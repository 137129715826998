export const environment = {
  production: false,
  apiUrl:'https://oneringproduction-api.azurewebsites.net/api/',
  apiCode:'v3EH/EEXb5fsfuYliEjodaSMIkh/bsnmq8jED/ISnSUMzvaYG8ZHOg==',
  dataLoadUrl:'https://alteryx-qa.honeywell.com/gallery/#!app/Master_PSG_DataSource_v1/60250cb36b96771c6ce9ab80',
  adalConfiguration : {
    tenant: '96ece526-9c7d-48b0-8daf-8b93c90a5d18',
    clientId: 'efc65517-6ee3-4b13-a0a0-0a86cf62a725',
    redirectUri: "https://www.honeywellcommandcentral.com",
    endpoints: {
      'https://oneringproduction-api.azurewebsites.net/':'50076a7d-9d6a-4726-8644-c41d1bacee35'
    },
    navigateToLoginRequestUrl: true,
    cacheLocation: 'localStorage',
   
  },
  localMode: false,
  versionNumber: "1.2.4",
  imageFileTypes:['jpg','jpeg',"png","bmp","tiff","gif"],
  pdfType:["pdf"],
  docTypes:["doc","docx","docm"],
  excelTypes:["csv","xls","xlsm","xlsx"],
  textTypes:["txt"],
  idleTimeBeforeWarning: 85,    // idle time before dialog is popped in minutes
  idleWarningTime: 300000,      // time dialog is displayed in msec before you are logged out - 10 minutes
  environmentVariable:'OneRing',
  sentryShowdialog: false,
  sentryEnvironment: 'Prod',
  sentryRelease: 'OneRingUI_Production_OneRingUI_QA_master_12_6_2024.1_12_11_2024.1'
};
